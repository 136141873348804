<template>
  <div class="slider-area pt-105">
    <template>
      <el-carousel :interval="5000" arrow="always" height="36.5vw">
        <el-carousel-item v-for="item in 4" :key="item">
          <img src="@/static/img/banner.jpg" width="100%">
        </el-carousel-item>
      </el-carousel>
    </template>
  </div>
</template>

<script>
export default {
  name: "banner",
};
</script>

<style>
</style>