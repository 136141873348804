import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from "element-ui";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("@/views/Home.vue")
  },
  {
    path: '/stop:id',
    name: 'stop',
    component: () => import("@/views/stop.vue")
  },
  {
    path: '/detalis:id',
    name: 'detalis',
    component: () => import("@/views/detalis.vue")
  },
  {
    path: '/order',
    name: 'order',
    component: () => import("@/views/order.vue")
  },
  {
    path: '/register',
    name: 'register',
    component: () => import("@/views/register.vue"),
    redirect: "/register/signin",
    children: [
      { path: '/register/signin', name: 'signin', component: () => import("@/components/register/signIn.vue") },
      { path: '/register/logon', name: 'logon', component: () => import("@/components/register/logon.vue") },
      { path: "/register/forget", name: "forget", component: () => import('@/components/register/forget.vue') },
    ]
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import("@/views/personal.vue"),
    redirect: "/personal/myself",
    children: [
      { path: "/personal/myself", name: "myself", component: () => import('@/components/personal/myself.vue') },
      { path: "/personal/address", name: "address", component: () => import('@/components/personal/address.vue') },
    ]
  },
  {
    path: '/wishlist',
    name: 'wishlist',
    component: () => import("@/views/wishlist.vue")
  },
  {
    path: '/transaction',
    name: 'transaction',
    component: () => import("@/views/transaction.vue")
  },
  {
    path:"/offer",
    name:'offer',
    component:()=>import("@/views/offer.vue"),
    redirect:"/offer/offerindex",
    children:[
      {path:"/offer/offerindex",name:"offerindex",component:()=>import('@/components/offer/offerindex.vue')},
      {path:"/offer/offerdetails:id",name:"offerdetails",component:()=>import("@/components/offer/offerDetails.vue")},
      {path:"/offer/offerplan",name:"offerplan",component:()=>import('@/components/offer/offerPlan.vue')}
    ]
  },
  {
    path:"/orderfrom:id",
    name:"orderfrom",
    component:()=>import('@/components/order/orderfrom.vue')
  },
  {
    path:"/ordercauselist:id",
    name:"ordercauseList",
    component:()=>import('@/components/order/ordercauseList.vue')
  }
 ]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === "myself" || to.name === "order" || to.name === "wishlist" || to.name === "transaction" ||to.name==="offer" ||to.name=="offerindex") {
    const tokne = localStorage.getItem('token')
    if (tokne) {
      next()
    }else{
      Message({
        message: "请登录",
        type: 'error'
      })
      next({ name: 'signin' })
    }
  } else {
    next()
  }
})

export default router
