<template>
  <div class="new-arrival pt-110"  v-if="list[0]">
    <div class="container">
      <div class="section-title text-center">
        <h2>Category classification</h2>
      </div>
      <div class="arrival-menu text-center pt-20">
        <button class="abtn"  v-for="item in list[0].child" :key="item.id" @click="goto(item.id)">{{item.name}}</button>
      </div>
      <div class="arrival-product pt-45">
        <div class="row grid">
          
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 grid-item cat1 cat4" v-for="item in list[0].child" :key="item.id">
            <div class="arrival-items arrival-new-items text-center mb-55" @click="goto(item.id)">
              <div>
                <img :src="item.pic_url" alt="bag" width="100%"/>
              </div>
              <div class="arrival-details position-relative pt-25">
                <h5><a href="javascript:0">{{item.name}}</a></h5>
                <ul class="rating">
                  <li v-for="item in 5" :key='item'><i class="las la-star"></i></li>
                </ul>
               
                <div class="buy-info">
                  <a
                    class="slider-btn add-btn float-left position-relative"
                    href="javascript:0"
                    >Add To Cart</a
                  >
                  <ul class="wishlist text-right">
                    <li>
                      <a href="javascript:0"
                        ><i class="lar la-heart"></i
                      ></a>
                    </li>
                    <li>
                      <button class="popbtn">
                        <i class="fas fa-search-plus"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="view-items ml-auto mr-auto mt-50">
          <a class="p-btn position-relative" href="javascript:0" @click="goto(7)">
            <span>Load more</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name:"newArrival",
  data() {
    return {
      list:[]
    }
  },
  methods:{
    goto(id){
       this.$router.push({ name:'stop',params:{id}}); 
    }
  },
  computed:{
    ...mapState(['goodsList'])
  },
  watch: {
    goodsList(newValue) {
      let a = JSON.parse(JSON.stringify(newValue))
      const b =a.map(item=>item.id)
      b.splice(0,1)
      b.splice(6,99)
      a[0].child.map((item,index)=>item.id=b[index])
      console.log(a);
      this.list = a
    },
  },
};
</script>
