<template>
  <div ref="header">
    <div class="header-1 pt-35">
      <div class="container">
        <div class="row">
          <div
            class="
              col-xl-2 col-lg-2 col-md-6 col-sm-6 col-7
              order-md-2 order-lg-1
            "
          >
            <div class="logo text-md-center text-lg-left">
              <a href="index1.html"
                ><img src="@/static/picture/logo.png" alt="aruk-logo"
              /></a>
            </div>
          </div>
          <div
            class="
              col-xl-7 col-lg-7 col-md-3
              order-md-1
              offset-sm-2 offset-md-0
              order-lg-2
              col-sm-auto
            "
          >
            <nav>
              <div id="mobile-menu" class="main-menu">
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="javascript:0" @click="goto"
                      >Product</a
                    >
                  </li>
                  <li>
                    <a href="javascript:0" @click="routerLink('order')"
                      >order</a
                    >
                  </li>
                    <li>
                    <a
                      href="javascript:0"
                      @click="routerLink('offer')"
                      >offer</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:0"
                      @click="routerLink('register')"
                      v-if="istoken"
                      >register</a
                    >
                  </li>
                 
                
                </ul>
              </div>
            </nav>
          </div>
          <div
            class="
              col-xl-3 col-lg-3 col-md-3 col-sm-3 col-5
              order-md-3 order-lg-3
            "
          >
            <div class="nav-icons">
              <ul>
                <li @click="routerLink('wishlist')">
                  <a href="javascript:0"
                    ><i class="fas fa-shopping-cart"></i>
                  </a>
                </li>
                <li class="position-relative" @click="routerLink('personal')">
                  <span class="product-cart"><i class="fas fa-user"></i></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mobile-menu"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "headers",
  created() {
    /*     console.log(this.$refs); */
  },
  methods: {
    goto(){
        if (this.$route.name != 'stop') {
        this.$router.push({ name: 'stop',params:{id:7} });
      }
    },
    routerLink(id) {
      if (this.$route.name != id) {
        this.$router.push({ name: id });
      }
    },
  },
  created() {
    const token = localStorage.getItem("token");
    if (token != null) {
      this.$store.commit("NoIsToken");
    }
  },
  computed: {
    ...mapState(["istoken"]),
  },
};
</script>

<style lang="less" scoped>
.header-1 {
  background: #f8f9fa;
}
</style>