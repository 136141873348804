<template>
   <div class="news-area pt-115">
      <div class="container">
        <div class="section-title text-center pb-45">
          <h2 class="text-uppercase">LATEST NEWS</h2>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-4">
            <div class="news-items mb-30 mb-md-0">
              <div class="news-img">
                <a href="blog.html"
                  ><img src="@/static/picture/012.png" alt="img1"
                /></a>
              </div>
              <div class="news-details pt-20">
                <div class="news-title pr-50">
                  <a href="news_detalis.html"
                    >Lorem Ipsum has been the industry sed do tempor tara</a
                  >
                </div>
                <span class="d-block">Jan 21, 2021 By Admin</span>
                <a
                  class="slider-btn d-inline-block position-relative mt-10"
                  href="news_detalis.html"
                  >Read More</a
                >
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 ml-30 mr-60">
            <div class="news-items mb-30 mb-md-0">
              <div class="news-img">
                <a href="blog.html"
                  ><img src="@/static/picture/02.png" alt="img1"
                /></a>
              </div>
              <div class="news-details pt-20">
                <div class="news-title pr-50">
                  <a href="news_detalis.html"
                    >Lorem Ipsum has been the industry sed do tempor tara</a
                  >
                </div>
                <span class="d-block">Jan 21, 2021 By Admin</span>
                <a
                  class="slider-btn d-inline-block position-relative mt-10"
                  href="news_detalis.html"
                  >Read More</a
                >
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 pl-xl-0 pr-xl-0">
            <div class="news-items">
              <div class="news-img">
                <a href="blog.html"
                  ><img src="@/static/picture/03.png" alt="img1"
                /></a>
              </div>
              <div class="news-details pt-20">
                <div class="news-title">
                  <a href="news_detalis.html"
                    >Lorem Ipsum has been the industry sed do tempor tara</a
                  >
                </div>
                <span class="d-block">Jan 21, 2021 By Admin</span>
                <a
                  class="slider-btn d-inline-block position-relative mt-10"
                  href="news_detalis.html"
                  >Read More</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name:"newArea"
}
</script>

<style>

</style>