import axios from "axios";
import { Message } from "element-ui";

const token = localStorage.getItem('token')

if (process.env.NODE_ENV==='development') {
  axios.defaults.baseURL='api'
}else{
  axios.defaults.baseURL="https://tile.dechaab.cn/web/index.php?_mall_id=2"
}

export const service = axios.create({
  timeout: 10000,
  headers: {
    'x-access-token': token || ''
  }
})

service.interceptors.request.use(config => {

  return config

})
service.interceptors.response.use(res => {
  if (res.data.code == 1) {
    Message({
      message: res.data.msg,
      type: "error"
    })
  }

  return res.data
}, err => {
  console.log(err)
  const msg = err.Message !== undefined ? err.Message : ""
  Message({
    message: "网络错误" + msg,
    type: 'error',
    duration: 3 * 1000
  })
  return Promise.reject(err)
})