<!--  -->
<template>
  <div id="app">
    <Headers />
    <router-view/>
<!--     <Footers /> -->
  </div>
</template>

<script>
import Home from "@/views/Home.vue";
import Headers from "@/views/header.vue";
import Footers from "@/views/footer.vue";
export default {
  name: "App",
  components: { Home, Headers, Footers },
  created(){
    this.$store.dispatch('getGoodsList')
  }
};
</script>

<style lang="less" scoped>
  @import url("@/static/css/animate.css");
  @import url("@/static/css/bootstrap.min.css");
  @import url("@/static/css/cf.errors.css");
  @import url("@/static/css/css2.css");
  @import url("@/static/css/default.css");
  @import url("@/static/css/font-awesome.min.css");
  @import url("@/static/css/jquery.nice-number.css");
  @import url("@/static/css/line-awesome.min.css");
  @import url("@/static/css/magnific-popup.css");
  @import url("@/static/css/mean-menu.css");
  @import url("@/static/css/normalize.css");
  @import url("@/static/css/owl.carousel.min.css");
  @import url("@/static/css/slick.css");
  @import url("@/static/css/style.css");
</style>