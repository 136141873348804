<template>
   <div class="instagram-area pt-110 pb-120">
      <div class="container">
        <div class="section-title text-center">
          <h2 class="text-uppercase">Instagram shop</h2>
          <span>@aruk_insta</span>
        </div>
        <div class="instagram-images d-flex pt-60">
          <div class="insta-imgs position-relative">
            <img src="@/static/picture/01.jpg" alt="img" />
            <a href="#"><i class="lab la-instagram"></i></a>
          </div>
          <div class="insta-imgs position-relative">
            <img src="@/static/picture/02.jpg" alt="img" />
            <a href="#"><i class="lab la-instagram"></i></a>
          </div>
          <div class="insta-imgs position-relative">
            <img src="@/static/picture/03.jpg" alt="img" />
            <a href="#"><i class="lab la-instagram"></i></a>
          </div>
          <div class="insta-imgs position-relative">
            <img src="@/static/picture/04.jpg" alt="img" />
            <a href="#"><i class="lab la-instagram"></i></a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name:"instagramArea"
}
</script>