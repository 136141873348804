import { getAxios } from '@/tools/api/request'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    goodsList:[], //商品头名称
    address:{}, //地址信息
    istoken:true, //是否关闭登录入口
  },
  mutations: {
    sentGoodList(state,data){
      state.goodsList=data
    },
    setIsToken(state){
      state.istoken=!state.istoken
    },
    NoIsToken(state){
      state.istoken=false
    },
    setAddress(state,data){
      state.address = data
      console.log(data);
      
    },
  },
  actions: {
    getGoodsList(context){
      getAxios('&r=api/cat/list').then(res=>{
        context.commit('sentGoodList',res.data.list)
      })
    }
  }
})
