<template>
   <div class="footer-area">
      <div class="container">
        <div class="footer-menu pt-120">
          <div class="row">
            <div class="col-xl-4 col-lg-3 col-md-6 col-sm-6">
              <div class="footer-widget mb-30 mb-lg-0 pt-15">
                <div class="logo pb-30">
                  <a href="index1.html"
                    ><img src="@/static/picture/logo.png" alt="img"
                  /></a>
                </div>
                <div class="footer-info">
                  <ul>
                    <li>
                      Email:
                      <span class="ml-5"
                        ><a
                          href="email-protection.html"
                          class="__cf_email__"
                          data-cfemail="563724233d16313b373f3a7835393b"
                          >[email&#160;protected]</a
                        ></span
                      >
                    </li>
                    <li>Phone: <span class="ml-5">+948 256 347 968</span></li>
                  </ul>
                  <p class="pr-65">
                    Subscribe to our newsleter and stay up to date with latest
                    offers and upcoming trends.
                  </p>
                  <div class="footer-input position-relative mt-30">
                    <input type="email" placeholder="Email" />
                    <button><i class="fas fa-arrow-right"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div class="footer-widget mb-30 mb-lg-0">
                <h4>About Us</h4>
                <ul class="footer-info pt-10">
                  <li>
                    <a
                      class="slider-btn position-relative d-inline-block"
                      href="about_us.html"
                      >Our Outlets</a
                    >
                  </li>
                  <li>
                    <a
                      class="slider-btn position-relative d-inline-block"
                      href="about_us.html"
                      >Sales and Retails</a
                    >
                  </li>
                  <li>
                    <a
                      class="slider-btn position-relative d-inline-block"
                      href="about_us.html"
                      >Areas we serve</a
                    >
                  </li>
                  <li>
                    <a
                      class="slider-btn position-relative d-inline-block"
                      href="about_us.html"
                      >Career at aruk</a
                    >
                  </li>
                  <li>
                    <a
                      class="slider-btn position-relative d-inline-block"
                      href="contact_page.html"
                      >Contact Us</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div class="footer-widget">
                <h4>Help and Support</h4>
                <ul class="footer-info pt-10">
                  <li>
                    <a
                      class="slider-btn position-relative d-inline-block"
                      href="about_us.html"
                      >Track my order</a
                    >
                  </li>
                  <li>
                    <a
                      class="slider-btn position-relative d-inline-block"
                      href="about_us.html"
                      >Our top products</a
                    >
                  </li>
                  <li>
                    <a
                      class="slider-btn position-relative d-inline-block"
                      href="shopping_cart.html"
                      >My cart</a
                    >
                  </li>
                  <li>
                    <a
                      class="slider-btn position-relative d-inline-block"
                      href="about_us.html"
                      >Sign in</a
                    >
                  </li>
                  <li>
                    <a
                      class="slider-btn position-relative d-inline-block"
                      href="checkout_page.html"
                      >Shipping Calculator</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6 pl-xl-0">
              <div class="footer-widget">
                <h4>Pages & Resources</h4>
                <ul class="footer-info pt-10">
                  <li>
                    <a
                      class="slider-btn position-relative d-inline-block"
                      href="about_us.html"
                      >About Us</a
                    >
                  </li>
                  <li>
                    <a
                      class="slider-btn position-relative d-inline-block"
                      href="shop_grid.html"
                      >Shop Page</a
                    >
                  </li>
                  <li>
                    <a
                      class="slider-btn position-relative d-inline-block"
                      href="shop_grid.html"
                      >Category page</a
                    >
                  </li>
                  <li>
                    <a
                      class="slider-btn position-relative d-inline-block"
                      href="about_us.html"
                      >Sign in page</a
                    >
                  </li>
                  <li>
                    <a
                      class="slider-btn position-relative d-inline-block"
                      href="404.html"
                      >404 page</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom mt-80">
        <div class="container">
          <div class="row">
            <div
              class="col-xl-4 col-lg-5 col-md-6 col-sm-12 order-md-2 order-lg-1"
            >
              <div class="copyright mt-15">
                <p>
                  Copyright © 2020 | Designed by
                  <a href="javascript:;" target="_blank">Wpsmasher</a>
                </p>
              </div>
            </div>
            <div class="col-xl-5 col-lg-3 col-md-12 order-md-1 order-lg-2">
              <ul class="social-icon text-center mt-15">
                <li>
                  <a href=""><i class="lab la-facebook-f"></i></a>
                </li>
                <li>
                  <a href=""><i class="lab la-twitter"></i></a>
                </li>
                <li>
                  <a href=""><i class="lab la-instagram"></i></a>
                </li>
                <li>
                  <a href=""><i class="lab la-vimeo"></i></a>
                </li>
              </ul>
            </div>
            <div
              class="col-xl-3 col-lg-4 col-md-6 col-sm-12 order-md-2 order-lg-3"
            >
              <div class="policy text-right mt-15">
                <a class="position-relative" href="#">Terms of Use </a>
                <a class="ml-40" href="#">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name:"foorers"
}
</script>
