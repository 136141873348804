import { service } from "@/tools/api/api"
import qs from"qs"

export function postAxios(data,url){
  return service({
    url,
    data:qs.stringify(data),
    method:"post",
    headers:{
      'Content-Type':'application/x-www-form-urlencoded',
    }
  })
}
export function getAxios(url){
  return service({
    url,
    method:"get"
  })

}
