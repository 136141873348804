<template>
  <div>
     <el-backtop></el-backtop>

    <Banner />

    <NewArrival />

    <NewArea />

    <InstagramArea />
  </div>
</template>

<script>
import Banner from "@/components/Home/banner.vue";
import NewArrival from "@/components/Home/newArrival.vue";
import NewArea from "@/components/Home/newsArea.vue";
import InstagramArea from "@/components/Home/instagramArea.vue";

export default {
  name: "home",
  components: { Banner, NewArrival, NewArea, InstagramArea },
};
</script>
